import React, {useEffect} from 'react'
import { walletsHistory } from '../Request/Admin';
import { Link } from 'react-router-dom';


const Wallets = () => {
    let mywallets = walletsHistory();
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

    const setCurrentwallet = (wallet) => {
        localStorage.setItem('adminhistorywalletSelected', JSON.stringify(wallet));
        const walletEvent = new Event('adminhistorywalletSelected');
        window.dispatchEvent(walletEvent);
    }
    useEffect(() => {
        document.title = 'ADMIN WALLET ADDRESSES';
        mywallets = walletsHistory();
    });
  return (
    <div id="page-content">        
        <Link to="/withdrawal" data-bs-toggle="offcanvas" data-bs-target="#admin-wallet-create-menu" className="default-link card card-style" style={{height: "90px"}}>
            <div className="card-center px-4">
                <div className="d-flex">
                    <div className="align-self-center">
                        <span className="icon icon-m rounded-s gradient-green shadow-bg shadow-bg-xs">
                        <i className="material-icons-outlined color-white" style={{fontSize: "28px", marginTop: "11px"}}>money_off_csred</i>
                        </span>
                    </div>
                    <div className="align-self-center ps-3 ms-1">
                        <h1 className="font-20 mb-n1">Create wallet</h1>
                        <p className="mb-0 font-12 opacity-70">Add new payment wallet</p>
                    </div>
                </div>
            </div>
        </Link>
        <h2 className='text-center mb-2'>
            existing payment wallets 
        </h2>
        {!mywallets.data.length > 0 ? <div>No wallets avaialable yet!</div>:
        <div className='content mt-0 mb-0 card shadow-sm bg-theme color-theme p-3'>
            {mywallets.data.map(wallet =>(
            <Link key={wallet.id} onClick={()=>setCurrentwallet(wallet)} data-bs-toggle="offcanvas" data-bs-target="#admin-wallet-history-menu"  to="#" className="d-flex pb-3">
                <div className="align-self-center">
                    <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                </div>
                <div className="align-self-center ps-1">
                    <h5 className="pt-1 mb-n1">{wallet.wallet_name}</h5>
                    <p className="mb-0 font-11 opacity-50 color-theme">{wallet.wallet_address}</p>
                </div>
            </Link>
            ))}
        </div>        
        }
    </div>
  )
}

export default Wallets