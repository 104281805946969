import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import {referralsHistory} from '../Request/Admin';

const Adminreferral = () => {
    let myreferralHistory = referralsHistory();

    const [pendingreferrals, setPendingreferral] = useState(0);
    const [activereferral, setActivereferral] = useState(0);
    const [completedreferral, setcompletedreferral] = useState(0);

    const setCurrentreferral = (referral) => {
        localStorage.setItem('adminhistoryreferralSelected', JSON.stringify(referral));
        const referralEvent = new Event('adminhistoryreferralSelected');
        window.dispatchEvent(referralEvent);
    }
   
    useEffect(() => {
        document.title = 'ADMIN REFERRALS HISTORY';
        myreferralHistory = referralsHistory();

        let pendingNum = 0;        
        let activeNum = 0;        
        let completedNum = 0;  

        myreferralHistory.forEach(referral => {
                
                if (referral.status === 'approved') {
                    activeNum ++;
                }
                if (referral.status === 'completed') {
                    completedNum ++;
                }
                if (referral.status === 'declined') {
                    completedNum ++;
                }
                if (referral.status === 'pending') {
                    pendingNum ++;
                }
                if (referral.status === 'waiting approval') {
                    pendingNum ++;
                }
            
        });
        
        setActivereferral(activeNum);
        setcompletedreferral(completedNum);
        setPendingreferral(pendingNum);
        
        if(window && window.init_template){
            window.init_template();
        }  
    }, [myreferralHistory, activereferral, completedreferral, pendingreferrals]);

  return (
    <div id="page-content" >        
        <h2 className='text-center mb-2'>
            referrals History
        </h2>
        <div className="card card-style">
            <div className="content mb-0">
                <div className="tabs tabs-pill" id="lend-customers">
                    <div className="tab-controls rounded-m p-1 overflow-visible">
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#pending-customers" aria-expanded="true">Pending</a>
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#active-customers" aria-expanded="false">Active</a>
                        <a className="font-13 rounded-s py-1 shadow-bg shadow-bg-s" data-bs-toggle="collapse" href="/#completed-customers" aria-expanded="false">Completed</a>
                    </div>
                    <div className="mt-3"></div>
                    {/* <!-- Tab Bills List --> */}
                    <div className="collapse show" id="pending-customers" data-bs-parent="#lend-customers">
                        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                        <div className="content mt-0 mb-0">
                            {!pendingreferrals > 0 ?
                            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                No pending referral records found for now
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                myreferralHistory.map(referral =>(
                                    (referral.status === 'pending' || referral.status === 'waiting approval') &&
                                    <Link key={referral.id} onClick={()=>setCurrentreferral(referral)} data-bs-toggle="offcanvas" data-bs-target="#admin-referral-history-menu"  to="#" className="d-flex pb-3">
                                        <div className="align-self-center">
                                            <span className="icon rounded-s me-2 gradient-red shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                        </div>
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{referral.inviter && referral.inviter.name}</h5>
                                            <p className="mb-0 font-11 opacity-50">Upliner</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-red-dark">{referral.invited && referral.invited.name}</h4>
                                            <p className="mb-0 font-12 opacity-50">Downliner</p>
                                        </div>
                                    </Link>
                                ))                
                                        
                            }
                        </div>                                          
                </div>

                <div className="collapse" id="active-customers" data-bs-parent="#lend-customers">
                        <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                        <div className="content mt-0 mb-0">
                            {!activereferral > 0 ?
                            <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                                <div className="content my-3">
                                    <div className="d-flex">
                                        <div className="align-self-center no-click">
                                            <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                        </div>
                                        <div className="align-self-center no-click">
                                            <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                                No active referral records found for now
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                                myreferralHistory.map(referral =>(
                                    referral.status === 'approved' &&
                                    <Link key={referral.id} onClick={()=>setCurrentreferral(referral)} data-bs-toggle="offcanvas" data-bs-target="#admin-referral-history-menu"  to="#" className="d-flex pb-3">
                                        <div className="align-self-center">
                                            <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                        </div>
                                        <div className="align-self-center ps-1">
                                            <h5 className="pt-1 mb-n1">{referral.inviter && referral.inviter.name}</h5>
                                            <p className="mb-0 font-11 opacity-50">Upliner</p>
                                        </div>
                                        <div className="align-self-center ms-auto text-end">
                                            <h4 className="pt-1 mb-n1 color-green-dark">{referral.invited && referral.invited.name}</h4>
                                            <p className="mb-0 font-12 opacity-50">Downliner</p>
                                        </div>
                                    </Link>
                                ))                
                                        
                            }
                        </div>                                          
                </div>
                
                {/* <!-- Tab Custom Payments--> */}
                <div className="collapse" id="completed-customers" data-bs-parent="#lend-customers">
                    <h6 className="text-center opacity-30 pb-3">Tap an Item below for More Details </h6>
                    <div className="content mt-0 mb-0">
                        {!completedreferral > 0 ?
                        <div className="card card-style alert alert-dismissible border border-fade-yellow show fade p-0 mb-3">
                            <div className="content my-3">
                                <div className="d-flex">
                                    <div className="align-self-center no-click">
                                        <i className="bi bi-exclamation-triangle font-36 color-yellow-dark d-block"></i>
                                    </div>
                                    <div className="align-self-center no-click">
                                        <p className="color-yellow-dark mb-0 font-500 font-14 ps-3 pe-4 line-height-s">
                                            No completed referrals record avaialable
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        :
                            myreferralHistory.map(referral =>(
                                (referral.status === 'completed' || referral.status === 'declined')  &&
                                <Link key={referral.id} onClick={()=>setCurrentreferral(referral)} data-bs-toggle="offcanvas" data-bs-target="#admin-referral-history-menu"  to="#" className="d-flex pb-3">
                                    <div className="align-self-center">
                                        <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-droplet font-18 color-white"></i></span>
                                    </div>
                                    <div className="align-self-center ps-1">
                                        <h5 className="pt-1 mb-n1">{referral.inviter && referral.inviter.name}</h5>
                                        <p className="mb-0 font-11 opacity-50">Upliner</p>
                                    </div>
                                    <div className="align-self-center ms-auto text-end">
                                        <h4 className="pt-1 mb-n1 color-theme">{referral.invited && referral.invited.name}</h4>
                                        <p className="mb-0 font-12 opacity-50">Downliner</p>
                                    </div>
                                </Link>
                            ))                
                        
                        }
                    </div>                   
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Adminreferral