
import React, {useEffect, useState, Fragment} from 'react';
import { useMutation } from "react-query";
import apiClient from "../../http-common";
import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';

const ManageInvestment = () => {

    const [investment, setinvestment] = useState('');   
    const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);
    const getDate = (date) => new Date(date).toDateString();

    const { isLoading: isApprovinginvestment, mutate: approveinvestment } = useMutation(        
        async () => {
          return await apiClient.post(`/api/approve-investment/${investment.id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );

    const { isLoading: isDcelininginvestment, mutate: declineinvestment } = useMutation(        
        async () => {
          return await apiClient.post(`/api/decline-investment/${investment.id}`);
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess(); 
            
          },
          onError: (err) => {
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );
    
    useEffect(() => {
        window.addEventListener('adminhistoryinvestmentSelected', function (e) {
            let selectedinvestment = localStorage.getItem('adminhistoryinvestmentSelected');
            if (selectedinvestment != null) {
                selectedinvestment = JSON.parse(selectedinvestment); 
                setinvestment(selectedinvestment);
            }
        }, false);
    })
  return (
    <div>
        <div id="admin-investment-history-menu" className="offcanvas offcanvas-bottom offcanvas-attached">
            <div className="menu-size" style={{height:"520px"}}>
                {investment !== '' &&
                <div className="content">
                <a href="/#" className="d-flex py-1 pb-4">
                    <div className="align-self-center">
                        <span className="icon rounded-s me-2 gradient-green shadow-bg shadow-bg-xs"><i className="bi bi-person-circle font-18 color-white"></i></span>
                    </div>
                    <div className="align-self-center ps-1">
                        <h5 className="pt-1 mb-n1">{investment.user.email}</h5>
                        <p className="mb-0 font-11 opacity-70">{getAmount(investment.amount)}</p>
                    </div>
                    <div className="align-self-center ms-auto text-end">
                    {investment.status === 'pending' && <h4 className="pt-1 font-14 mb-n1 color-yellow-dark">{investment.status}</h4>}
                    {investment.status === 'waiting approval' && <h4 className="pt-1 font-14 mb-n1 color-yellow-dark">{investment.status}</h4>}
                    {investment.status === 'approved' && <h4 className="pt-1 font-14 mb-n1 color-green-dark">{investment.status}</h4>}
                    {investment.status === 'completed' && <h4 className="pt-1 font-14 mb-n1 color-theme">{investment.status}</h4>}
                    {investment.status === 'declined' && <h4 className="pt-1 font-14 mb-n1 color-red-dark">{investment.status}</h4>}
                    <p className="mb-0 font-11"> {getDate(investment.created_at)}</p>
                    </div>
                </a>
                <div className="row">
                    <strong className="col-5 color-theme">Investment Amount</strong>
                    <strong className="col-7 text-end">{getAmount(investment.amount)}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>

                    <strong className="col-5 color-theme">Investment Date </strong>
                    <strong className="col-7 text-end">{getDate(investment.created_at)}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>

                    <strong className="col-5 color-theme">Investment Duration </strong>
                    <strong className="col-7 text-end">{investment.duration}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>

                    <strong className="col-5 color-theme">User Email</strong>
                    <strong className="col-7 text-end">{investment.user.email}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div> 

                    <strong className="col-5 color-theme">Payment Method</strong>
                    <strong className="col-7 text-end color-highlight p-4">{investment.payment_method}</strong>
                    <div className="col-12 mt-2 mb-2"><div className="divider my-0"></div></div>
                      
                    <strong className="col-5 color-theme">Payment Proof</strong>
                    <div className="col-12 text-center">
                        {investment.payment_proof !== null &&
                        <img className='img-fluid' src={investment.payment_proof} alt='payment proof' />
                        }
                    </div>
                    <div className="col-12 mt-2 mb-4"><div className="divider my-0"></div></div>


                    {(investment.status === 'pending' || investment.status === 'waiting approval' )&&
                    <Fragment>
                        <div className="col-6">
                            <div onClick={approveinvestment} className="btn btn-s btn-full gradient-green shadow-bg shadow-bg-xs">
                            {isApprovinginvestment ? <span>Approving...</span>: <span>Approve</span>} 
                            </div>
                        </div>
                        <div className="col-6">
                            <div onClick={declineinvestment} className="btn btn-s btn-full gradient-red shadow-bg shadow-bg-xs">
                                {isDcelininginvestment ? <span>Declining...</span>: <span>Decline</span>} 
                            </div>
                        </div>
                    </Fragment>                    
                    }
                </div>
            </div>
                }
            </div>
        </div>
    </div>
  )
}

export default ManageInvestment