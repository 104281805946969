import React, {useEffect} from 'react';
import apiClient from "../../http-common";
import { useQuery } from "react-query";

export const UpdateText = ()=> {
    const { isLoading: isLoadingWallets, refetch: getWallets } = useQuery(
        "wallet-balances",
        async () => {
          return await apiClient.get("/api/balance");
        },
        {

          
          enabled: true,
          onSuccess: (res) => {
            if (res.data.status === 'success') {    

                let balanceSheet = {
                    'ref_bal': res.data.ref_bal,
                    'account_balance' : res.data.account_balance,
                    'per_seconds_earning': res.data.per_seconds_earning,
                    'current_earning': res.data.current_earning,
                    'bonus_balanace': res.data.bonus_balanace,
                    'current_investment': res.data.current_investment
                }
                localStorage.setItem('binaryfair-wallet-balances', JSON.stringify(balanceSheet));
                const event = new Event('updatedStorage');
                window.dispatchEvent(event);
            }
          },
          onError: (err) => {
            // triggerError(err.response?.data || err);
          },
        }
      );
    
      useEffect(() => {
        getWallets();
      

      }, [isLoadingWallets]);


    return (<div style={{display: "none"}}>
        {isLoadingWallets ? <span>updating...</span> : <span>updated now</span>}
    </div>)
}


export const referralBalance = () => {
    let balance = 0;
    let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
    if (storedBalance !== null) {
        let data = JSON.parse(storedBalance);
        balance = parseFloat(data.ref_bal);
    }
    return balance;

}


export const accountBalance = () => {
    let balance = 0;
    let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
    if (storedBalance !== null) {
        let data = JSON.parse(storedBalance);
        balance = parseFloat(data.account_balance);
    }
    return balance;
}

export const perSecondsEarning = () => {
  let balance = 0;
  let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
  if (storedBalance !== null) {
      let data = JSON.parse(storedBalance);
      balance = data.per_seconds_earning;
  }
  return balance;
}

export const currentEarning = () => {
  let balance = 0;
  let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
  if (storedBalance !== null) {
      let data = JSON.parse(storedBalance);
      balance = data.current_earning;
  }
  return balance;
}

export const bonusBalance = () => {
  let balance = 0;
  let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
  if (storedBalance !== null) {
      let data = JSON.parse(storedBalance);
      balance = data.bonus_balanace;
  }
  return balance;
}


export const currentInvestment = () => {
  let balance = 0;
  let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
  if (storedBalance !== null) {
      let data = JSON.parse(storedBalance);
      balance = data.current_investment;
  }
  return balance;
}


