import React, {useState, useEffect} from 'react';
import { accountBalance, referralBalance, bonusBalance } from '../Request/Wallets';


import {
    triggerSuccess,
    triggerError,
    setErrorMessage,
    setSuccessMessage,
  } from '../Request/StatusFormatter';
  
import { useMutation } from "react-query";
import apiClient from "../../http-common";

const getAmount = (amount)=> new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', currencySign: 'accounting' }).format(amount);

const Withdrawal = () => {
    const initialWithdrawal = {
        amount: '',
        wallet: '',
        bitcoinAddress: '',
        walletId: ''

    }
    const [accBalance, setaccountBalance] = useState(accountBalance());
    const [refBalance, setreferralBalance] = useState(referralBalance());
    const [bonBalance, setbonusBalance] = useState(bonusBalance());

     const newaccountBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.account_balance);
        }
        return balance;
    }
    
    const newreferralBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = parseFloat(data.ref_bal);
        }
        return balance;
    
    }

    const newbonusBalance = () => {
        let balance = 0;
        let storedBalance = localStorage.getItem('binaryfair-wallet-balances');
        if (storedBalance !== null) {
            let data = JSON.parse(storedBalance);
            balance = data.bonus_balanace;
        }
        return balance;
      }

    const [withdrawalForm, setWithdrawalForm] = useState(initialWithdrawal);
    // const [formstatus, setFormstatus] = useState('');
    const [formError, setFormError] = useState('');
    
    let wallets = localStorage.getItem('wallets');
    if (wallets != null) {
        wallets = JSON.parse(wallets);
    }  
    const onChange = (e) =>
    setWithdrawalForm({ ...withdrawalForm, [e.target.name]: e.target.value });

    const { isLoading: isSendingRequest, mutate: postWithdrawal } = useMutation(
        
        async () => {
           
            setFormError('');
          return await apiClient.post(`/api/withdraw`, {
            amount: withdrawalForm.amount,
            source: withdrawalForm.wallet,
            wallet_address: withdrawalForm.bitcoinAddress,
            wallet_name: withdrawalForm.walletId
          });
        },
        {
          onSuccess: (res) => {
            setSuccessMessage(res.data);
            triggerSuccess();
          },
          onError: (err) => {            
            setErrorMessage(err.response?.data || err);         
            triggerError();
          },
        }
      );
      const {amount, wallet} = withdrawalForm;
        const submitForm = () => {
            if(wallet === 'referral' && amount > refBalance){
                setFormError('withdrawal amount must not exceed '+refBalance)
                return
            }
            if(wallet === 'investment' && amount > accBalance){
                setFormError('withdrawal amount must not exceed '+accBalance)
                return
            }
            postWithdrawal();
        }

        useEffect(() => {
            window.addEventListener('updatedStorage', function (e) {
                setaccountBalance(newaccountBalance());
                setreferralBalance(newreferralBalance());
                setbonusBalance(newbonusBalance());
          }, false);
    
          });
  return (
    <div id="menu-withdrawal" className="offcanvas offcanvas-bottom offcanvas-attached ">
        <div className="menu-size" style={{height: "500px"}}>
                <div className="d-flex mx-3 mt-3 py-1">
                    <div className="align-self-center">
                        <h1 className="mb-0">Withdraw Your Funds</h1>
                    </div>
                    <div className="align-self-center ms-auto">
                        <a href="/#" className= "ps-4 shadow-0 me-n2" data-bs-dismiss="offcanvas">
                            <i className="bi bi-x color-red-dark font-26 line-height-xl"></i>
                        </a>
                    </div>
                </div>
                <div className="divider divider-margins mt-3"></div>
                <div className="content mt-0">
                    <div className="form-custom form-label form-icon">            
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>account_balance_wallet</i>
                        <select onChange={onChange} name="wallet" className="form-select bg-theme color-theme rounded-xs" id="withdrawWallet" aria-label="Floating label select example">
                            <option value="">Select Option </option>
                            <option value="investment">Investment Earnings ({getAmount(accBalance)})</option>
                            <option value="referral">Referral Balance ({getAmount(refBalance)})</option>
                            {/* <option value="bonus">Bonus Balance ({getAmount(bonBalance)})</option> */}
                            {/* <option value="paystack">Referral Balance (&#8358; 20,000)</option>                            */}
                        </select>
                        <label htmlFor="withdrawWallet" className="form-label-always-active color-highlight font-11">Choose Wallet </label>
                    </div>
                    
                    <div className="pb-3"></div>
                    <div className="form-custom form-label form-icon">
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>money</i>
                        <input onChange={onChange} type="number" name="amount" className="form-control bg-theme color-theme rounded-xs" id="withdrawAmount" placeholder="enter amount"/>
                        <label htmlFor="withdrawAmount" className="form-label-always-active color-highlight font-11">Amount</label>
                        <span className="font-10">( Currency: USD )</span>
                    </div>
                    <div className="pb-2"></div>

                    <div className="pb-2"></div>
                    <div className="form-custom form-label form-icon">
                    <i translate="no" className="material-icons-sharp" style={{fontSize: "17px"}}>currency_bitcoin</i>
                        <select onChange={onChange} type="text" name="walletId" className="form-control bg-theme text-theme rounded-xs" id="walletId" placeholder="select wallet">
                            <option value="">Select Payment Method</option>
                            {wallets !== null &&
                            wallets.map(wallet => (
                                <option key={wallet.id} value={wallet.wallet_name}> {wallet.wallet_name}</option>
                            ))
                            }
                        </select>
                        <label htmlFor="walletId" className="color-highlight form-label-always-">Payment Method</label>
                        <span>(required)</span>
                    </div>

                    <div className="pb-3"></div>
                    <div className="form-custom form-label form-icon">
                        <i translate="no" className="material-icons-outlined"  style={{fontSize: "18px"}}>money</i>
                        <input onChange={onChange} type="text" name="bitcoinAddress" className="form-control bg-theme color-theme rounded-xs" id="withdrawAmount" placeholder='enter wallet address'/>
                        <label htmlFor="bitcoinAddress" className="form-label-always-active color-highlight font-11">Wallet Address</label>
                        <span className="font-10">( required )</span>
                    </div>
                    <div className="pb-2"></div>
                </div>
                
                {formError !== '' && 
                <div className="alert bg-fade-red color-red-dark alert-dismissible rounded-s fade show" role="alert">
                   <i className="bi bi-exclamation-triangle pe-2"></i>
                    {formError}
               </div>}
                <div onClick={submitForm} className="mx-3 mb-3 btn btn-full gradient-green shadow-bg shadow-bg-s">
                {isSendingRequest ?<span>Sending...</span>
                :
                <span>withdraw funds</span>
                }
                </div>
            </div>
        </div>
  )
}

export default Withdrawal